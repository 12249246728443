<template>
  <div class="signInLink">
    <v-dialog v-model="signInDialog" width="500" persistent>
      <v-btn id="sign_in" slot="activator" color="flat small" :ripple="false">
        {{ $t("message.mainNav.signIn") }}
      </v-btn>

      <v-card class="signin-card">
        <v-btn
          id="btn_cancel"
          color="primary"
          flat
          @click="signInDialog = false"
          >X</v-btn
        >

        <v-card-title class="text-xs-center" primary-title>{{
          title
        }}</v-card-title>

        <v-card-text v-if="!showForgot">
          <div class="signin-body">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field v-model="username" :rules="emailRules" required>
                <template slot="label">{{ $t("message.email") }}</template>
              </v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                 @click:append="showPassword = !showPassword"
                :rules="passwordRules"
                required
                v-on:keyup.enter="validate"
              >
                <template slot="label">{{ $t("message.password") }}</template>
              </v-text-field>

              <p>
                <a @click="toggleForgot">{{ $t("message.mainNav.forgot") }}</a>
              </p>

              <v-btn id="btn_signin" :disabled="!valid" @click="validate">{{
                $t("message.mainNav.signIn")
              }}</v-btn>

              <div class="message">{{ message }}</div>
              <div style="font-size: 10px; color: #454545; margin-top: 15px;">v3.20.2</div>
            </v-form>
          </div>
        </v-card-text>

        <v-card-text v-if="showForgot">
          <div class="signin-body">
            <v-form v-model="validForgot" ref="form" lazy-validation>
              <v-text-field
                v-model="usernameForgot"
                :rules="emailRules"
                required
                v-on:keyup.enter="validateForgot"
              >
                <template slot="label">{{ $t("message.email") }}</template>
              </v-text-field>

              <v-btn
                id="btn_signin"
                :disabled="!validForgot"
                @click="validateForgot"
                >{{ $t("message.sendPass") }}</v-btn
              >

              <p>
                <a @click="toggleForgot">{{ $t("message.cancel") }}</a>
              </p>
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Facebook from "@/components/Facebook.vue";
const axios = require("axios");
import LogRocket from 'logrocket';
export default {
  name: "SignInLink",
  data: function () {
    return {
      message: "",
      valid: true,
      validForgot: true,
      debug: "test",
      dialog: false,
      username: "",
      password: "",
      showPassword: false,
      usernameForgot: "",
      email: "",
      url: window.location.protocol + "//" + window.location.host,
      loggedIn: false,
      showForgot: false,
    };
  },
  components: {
    // Facebook
  },
  computed: {
    profileName: function () {
      return this.$store.state.profileName;
    },
    signInDialog: {
      get: function () {
        return this.$store.state.signInDialog;
      },
      set: function (newValue) {
        this.$store.commit("toggleSignInDialog", {});
      },
    },
    title: function () {
      if (this.showForgot === false) {
        return this.$t("message.mainNav.signIn");
      } else {
        return this.$t("message.mainNav.forgot");
      }
    },
    emailRules: function () {
      return [
        (v) => !!v || this.$t("message.emailReq"),
        (v) => /.+@.+/.test(v) || this.$t("message.emailVal"),
      ];
    },
    passwordRules: function () {
      return [(v) => !!v || this.$t("message.passwordReq")];
    },
  },
  methods: {
    toggleForgot: function () {
      // console.log('toggled')
      this.showForgot = !this.showForgot;
    },
    validate() {
      if (this.$refs.form.validate()) {
        // this.snackbar = true
        this.loginUser();
      }
    },
    validateForgot() {
      if (this.$refs.form.validate()) {
        // post to API forgot password send
        let vm = this;
        axios({
          method: "post",
          withCredentials: true,
          url: vm.$root.urls.api + "/email/sendPasswordReset",
          data: {
            username: vm.usernameForgot,
          },
        })
          .then(function (response) {
            if (response.status === 200) {
              // console.log("yes");
              alert(vm.$t("message.sendPassSent"));
              vm.showForgot = false;
            }
          })
          .catch(function (error) {
            // console.log("no");
            // vm.title = "Please try again"
          });

        // this.snackbar = true
        // console.log('email sent')
      }
    },
    loginUser: function () {
      let vm = this;
      let dataUsername = this.username;
      let dataPassword = this.password;
      let profileName = this.username.toUpperCase().toString();
      // this.$cookie.set("redd-username", profileName, 1);
      axios({
        method: "post",
        withCredentials: true,
        url: vm.$root.urls.api + "/login",
        data: {
          username: dataUsername,
          password: dataPassword,
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            vm.dialog = false;
            vm.loggedIn = true;
            let token = response.data.token;

            let userData = response.data.user;
            if(typeof userData.companyProvince === 'undefined') userData.companyProvince = ''
            if(typeof userData.companyDistrict === 'undefined') userData.companyDistrict = ''
            if(typeof userData.companySubDistrict === 'undefined') userData.companySubDistrict = ''
            if(typeof userData.companyPostcode === 'undefined') userData.companyPostcode = ''
            if(typeof userData.companyCountry === 'undefined') userData.companyCountry = 'Thailand'

            vm.$store.commit("login", {
              user: userData,
              token
            });
            localStorage.setItem("reddtoken", token);

            // identify user to log rocket
            LogRocket.identify(response.data.user._id, {
              name: `${response.data.user.title} ${response.data.user.firstname} ${response.data.user.lastname}`,
              email: response.data.user.username
            });

            // if user is on booking confirmation page, refresh page
            var url = String(window.location);
            if (url.includes("book-confirmation")) {
              window.location.reload();
            }
          }
        })
        .catch(function (error) {
          // console.log('no')
          vm.message = "Login failed. Please try again";
        });
    },
  },
  mounted() {
    if(process.env.VUE_APP_UNAME) this.username = process.env.VUE_APP_UNAME;
    if(process.env.VUE_APP_PWRD) this.password = process.env.VUE_APP_PWRD;
  }
};
</script>

<style scoped lang="scss">
.message {
  font-weight: 700;
  color: #dd0030;
}
</style>
